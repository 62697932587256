@import 'tailwindcss/base';
@import 'tailwindcss/components';  
@import 'tailwindcss/utilities';

* {
  box-sizing: border-box;
  font-family: Mori;
}

.eq-filter {
  height: 100%;
  flex-direction: column;
}

@media (max-width: 1280px) {
  .eq-filter {
    flex-direction: column;
    min-width: 100%;
    padding: 0;
  }
}

.volume-slider::-webkit-slider-thumb {
  width: 12px;
  -webkit-appearance: none;
  appearance: none;
  height: 12px;
  cursor: pointer;  
  background: white;
  border-radius: 50%;
  box-shadow: -2505px 0 0 2500px #605E5C;
}

div[role="tooltip"] {
	pointer-events: none;
}