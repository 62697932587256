@font-face {
  font-family: "Mori";
  src: url("./assets/fonts/PPMori-Extralight.otf") format("opentype");
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: "Mori";
  src: url("./assets/fonts/PPMori-ExtralightItalic.otf") format("opentype");
  font-weight: light;
  font-style: italic;
}

@font-face {
  font-family: "Mori";
  src: url("./assets/fonts/PPMori-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mori";
  src: url("./assets/fonts/PPMori-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Mori";
  src: url("./assets/fonts/PPMori-SemiBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Mori";
  src: url("./assets/fonts/PPMori-SemiBoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}
